import { render, staticRenderFns } from "./VideoOnDemand.vue?vue&type=template&id=07eb8fdc&scoped=true"
import script from "./VideoOnDemand.vue?vue&type=script&lang=js"
export * from "./VideoOnDemand.vue?vue&type=script&lang=js"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./VideoOnDemand.vue?vue&type=style&index=1&id=07eb8fdc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07eb8fdc",
  null
  
)

export default component.exports